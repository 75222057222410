import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SentryInfo, SentryLevel } from "utils/sentry";
const pjson = require("./../package.json");

const inAttributte = (target, labelName) => {
  return target ? `, ${labelName}: ${target.toUpperCase()}` : "";
};

let prefix = "admin";
prefix = prefix.concat(`_${process.env.REACT_APP_SENTRY_ENV.toUpperCase()}`);
const currentVersion = pjson.version;

if (
  !["LOCALDEV", "LOCALPROD", "LOCALSTAGING", "DEVELOPMENTADMIN"].includes(
    process.env.REACT_APP_ENV
  )
) {
  window.console.log = () => {};
  window.console.debug = () => {};
  window.console.info = () => {};
  window.console.warn = () => {};
  window.console.error = () => {};

  Sentry.init({
    dsn: "http://3aeafd3553a004f83f069886302e9349@stry.mgc.pw/7",
    integrations: [
      new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
        xhr: true,
        location: true,
      }),
    ],

    release: `${prefix}@${currentVersion}`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENV || "LOCAL",
    initialScope: (scope) => {
      scope.setTags({ errorType: "UNHANDLED ERROR" });
      scope.setLevel(SentryLevel.Fatal);
      scope.setTags(SentryInfo().tags);
      scope.setUser(SentryInfo().user);

      return scope;
    },
    // custom breadcrumb
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        const { target } = hint.event;

        const attributte = {
          innerText: inAttributte(target?.innerText, "innerText"),
          id: inAttributte(target?.id, "id"),
          ariaLabel: inAttributte(target?.ariaLabel, "ariaLabel"),
          name: inAttributte(target?.name, "name"),
          textContent: inAttributte(target?.textContent, "textContent"),
        };
        const message = `tagName : ${target?.tagName}${attributte.innerText}${attributte.id}${attributte.ariaLabel}${attributte.name}`;

        if (
          target?.tagName === "path" ||
          message === "tagName : svg" ||
          message === "tagName : DIV"
        )
          return breadcrumb;

        breadcrumb.message = message;
      }
      return breadcrumb;
    },
  });
}
