import "./sentry_instruments.js";
import { ThemeProvider } from "@material-ui/core/styles";
import { LSname } from "localstorage";
import { fetchNotif, watchNotif } from "mongoservices/setup";
import { useEffect } from "react";
import store from "store/GeneralStore";
import "./App.css";
import Routes from "./Routes";
import ThemeStyles from "./ThemeStyles";

function App() {
  useEffect(() => {
    const profile = JSON.parse(
      localStorage.getItem(LSname.userProfile) || null
    );
    if (profile) {
      fetchNotif();
      watchNotif();
      store.setState({ acl: profile.acl });
    }

    document.title = `${process.env.REACT_APP_TITLE} - ${document.title}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
